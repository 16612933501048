import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
@Injectable()
export class coreUrlList {
    comm = environment.commonurl;
    onDemand = environment.ondemandUrl;
    // File Upload
    uploadFile: string = `${this.comm}/upload/upload`;

    //  App Setting
    appSetting: string = `${this.comm}/admin/appSetting`;
    getAppSetting: string = `${this.comm}/admin/getAppSetting`;
    getSuperAdminAppSetting: string = `${this.comm}/admin/getSuperAdminAppSetting`;
 // get signed url
   // getSignedUrl: string = `${this.comm}/upload/getSignedUrl`;

    // Auth
    login: string = `${this.comm}/store/login`;
    forgotPassword: string = `${this.comm}/store/forgotPassword`;
    forgotPasresendOtpsword: string = `${this.comm}/store/resendOtp`;
    sendOTPToStore: string = `${this.onDemand}/admin/store/sendOtp`
    setPassword: string = `${this.comm}/store/setPassword`;
    verifyOtp: string = `${this.comm}/store/verifyOtp`;
    resetPassword: string = `${this.comm}/store/resetPassword`;
    uploadDocument: string = `${this.onDemand}/store/document`;
    getStoreDocuments: string = `${this.comm}/store/document`;

    getDocument: string = `${this.onDemand}/store/getDocumentList`;
    getDocumentList: string = `${this.comm}/store/document`
    changePassword: string = `${this.comm}/admin/panel/changepass`;


    // Sign Up
    signup: string = `${this.comm}/store/signup`;


    // Store
    store: string = `${this.onDemand}/admin/store`;
    storeById: string = `${this.onDemand}/admin/store/getStoreById`;
    storeByPhone: string = `${this.comm}/store/isVerified`
    storeTokenById: string = `${this.onDemand}/admin/store/getStoreTokenById`;
    getRevenueStore: string = `${this.onDemand}/admin/store/revenue/store`;
    getStoreRevenueById: string = `${this.comm}/admin/store/getAllMerchantTotalCommission`;
    getPendingOrderCount: string = `${this.onDemand}/admin/pendingOrderCount`;
    getStorePaymentHistory: string = `${this.onDemand}/admin/payHistory`;
    addEditTax: string = `${this.onDemand}/admin/tax`;
    addEditGetTags: string = `${this.onDemand}/admin/tags`;
    getMerchantWiseCommission: string = `${this.onDemand}/admin/payoutList`
    holidaySlot: string = `${this.onDemand}/admin/store/holidayTimingService`
    getInvoicesList: string = `${this.onDemand}/admin/store/getStoreInvoices`;
    createStoreInvoice: string = `${this.onDemand}/admin/store/createStoreInvoice`;


    // Time Slots
    addTimeSlot: string = `${this.onDemand}/admin/addTimeSlot`;
    getTimeSlots: string = `${this.onDemand}/admin/getTimeSlot`;


    // Otlet
    outlet: string = `${this.onDemand}/admin/store/outlet`;
    //vehicleId
    vehicleId: string = `${this.onDemand}/store/vehicleMultiOrder`;

    //calculatePricing
    calculateMultiPricing: string = `${this.onDemand}/admin/calculateMultiPricing`;


    // Zone
    getZones: string = `${this.onDemand}/zone/getZones`;
    getGeofence: string = `${this.onDemand}/zone/geofence`;


    // Products
    products: string = `${this.onDemand}/admin/store/product`;
    variant: string = `${this.onDemand}/admin/store/variant`;
    deleteSelectedProducts: string = `${this.onDemand}/admin/store/deleteSelectedProduct`;
    deleteAllMerchantProducts: string = `${this.onDemand}/admin/store/deleteAllProduct`;
    importProductsForStore: string = `${this.onDemand}/admin/store/bulkUploadProduct`;
    exportProducts: string = `${this.onDemand}/admin/store/getAllProductExport`;
    getInventoryProducts: string = `${this.onDemand}/admin/store/getStoreInventory`;
    updateCloneProducts: string = `${this.onDemand}/admin/store/updatecloneProducts`;
    getPrototypeProducts: string = `${this.onDemand}/admin/store/getprototypeProducts`;
    addVariantToInventory: string = `${this.onDemand}/admin/store/cloneProduct`;
    getProductById: string = `${this.onDemand}/admin/store/itembyid`;
    addgetTable: string = `${this.onDemand}/admin/store/table`;

    //Rider-Tracking
    ridertracking: string = `${this.onDemand}/wallet/riderTracking`;
    riderStatus: string = `${this.onDemand}/wallet/getRiderStatus`;
    generateWalletTopUpReceipt: string = `${this.onDemand}/wallet/generateWalletTopUpReceipt`;

    // Brands
    brand: string = `${this.onDemand}/admin/store/brand`;


    // Employee
    addEmployee: string = `${this.onDemand}/store/employee/signup`;
    updateEmployee: string = `${this.onDemand}/store/employee/updateEmployee`;
    getEmployees: string = `${this.onDemand}/store/allEmployee`;
    getAllEmployees: string = `${this.onDemand}/admin/store/allEmployee`;
    deleteEmployee: string = `${this.onDemand}/store/employee/delete`;
    getEmployeeyId: string = `${this.onDemand}/store/employee/employeeByid`;
    assisnOrderToEmployee: string = `${this.onDemand}/store/assignOrder`;


    // Category
    getStoreSubCatAndCat: string = `${this.onDemand}/admin/store/getStoreSubCatAndCat`;
    getCatWithProdThroughStoreId: string = `${this.onDemand}/admin/store/getcategories`;
    getAllBrandsWithProdThroughStoreId: string = `${this.onDemand}/admin/store/getBrandsByStore`;
    getCatWithProdThroughType: string = `${this.onDemand}/admin/store/getcategoriesbystoretype`;
    getAllBrandsWithProdThroughType: string = `${this.onDemand}/admin/store/getBrandsByStoreType`;
    getSubCatWithProdThroughType: string = `${this.onDemand}/admin/store/getSubCategoriesByStoreType`;
    getAllStoreCategories: string = `${this.onDemand}/admin/store/category`;


    // Notification
    getNotification: string = `${this.onDemand}/store/notification`;
    getUserRating: string = `${this.onDemand}/store/rating`;
    productRating: string = `${this.onDemand}/store/productRating`;


    // Subscribe To Notification
    subscribeToNotification: string = `${this.onDemand}/store/subscribe`;


    // Order
    getStoreOrders: string = `${this.onDemand}/admin/store/orders/all`;
    getEmployeeOrders: string = `${this.onDemand}/admin/store/employee/orders`;
    getStoreOrderDetail: string = `${this.onDemand}/admin/store/order`;
    getRating: string = `${this.onDemand}/store/rating`;
    getAllOrdersCSV: string = `${this.onDemand}/admin/getAllOrdersCSV`;
    createOrderByAdmin: string = `${this.onDemand}/store/admin/createOrderByAdmin`;
    createOrderByMerchant: string = `${this.onDemand}/store/orderByMerchant`;
    createCodDisputeByMerchant: string = `${this.onDemand}/store/createCodReuest`;
    multiOrderByMerchant: string = `${this.onDemand}/store/multiOrderByMerchant`;
    orderByAggregator: string = `${this.onDemand}/store/orderByAggregator`;
    getOrderInvoice: string = `${this.onDemand}/admin/store/pdfOrder`;
    getOrderInvoiceByMerchant: string = `${this.onDemand}/admin/store/pdfOrderByMerchant`;
    getDeliveryCharges: string = `${this.onDemand}/admin/calculatePricing`;
    getDiscountedPrice: string = `${this.onDemand}/admin/discountPricing`;
    editOrderByMerchant: string = `${this.onDemand}/store/editOrderByMerchant`;
    editOrderDesitnationByMerchant: string = `${this.onDemand}/store/editOrderDesitnationByMerchant`



    // Wallet
    getSetWallet: string = `${this.onDemand}/wallet/primaryWallet`;
    getTransactionList: string = `${this.onDemand}/wallet/primaryWalletTransaction`;
    getHeldTransactionList: string = `${this.onDemand}/wallet/holdingTransaction`;
    getTopupVouchersList: string = `${this.onDemand}/wallet/walletVoucher`;
    addFundsToWallet: string = `${this.onDemand}/wallet/addMoneyByUser`;
    approveVoucherByMerchant: string = `${this.onDemand}/wallet/verifyPayment`;
    releaseHeldAmount: string = `${this.onDemand}/wallet/approveCod`;

    //Vehicl
    getVehiclesList: string = `${this.onDemand}/admin/vehicleList`;
    getRiderDetail: string = `${this.onDemand}/wallet/rider`;
    //deals
    getDeal: string = `${this.comm}/admin/getDeals?deals=true`;
    //cancel reason from dispatcer
    getReasonsFromDispatcher: string = `${this.onDemand}/admin/getReasonsFromDispatcher`;
    //latest order 
    latestOrder: string = `${this.onDemand}/admin/store/getLatestOrderByPhone`;

    walletDisabledByMerchant: string = `${this.onDemand}/admin/store/walletDisabledByMerchant`


    //bankTranser 
    ngeniusAccessToken: string = `${this.onDemand}/wallet/bankTransferAccessToken`

    //checkExistingAggOrder
    checkExistingAggOrder: string = `${this.onDemand}/store/checkExistingAggOrder`
    //updateCustomOffer
    updateCustomOffer: string = `${this.onDemand}/store/updateCustomOffer`

    getSourceBookingRules : string = `${this.onDemand}/admin/store/getSourceBookingRules`
    getCustomAddresses : string = `${this.onDemand}/admin/store/getCustomAddresses`

    getRiderLocation : string = `${this.onDemand}/store/riderLocationMerchant`


    uploads3File: string = `${this.comm}/upload/uploadv3`;
    getSignedUrl: string = `${this.onDemand}/admin/getSignedUrl`;

    getSignedUrlcomm: string = `${this.comm}/upload/getSignedUrl`;

    verifyData: string = `${this.comm}/store/verifyData`

    broadCastToMerchants: string = `${this.onDemand}/admin/broadCastToMerchants`

    read: string = `${this.onDemand}/admin/broadCastToMerchants/read`
    getPricingForEditDestination : string = `${this.onDemand}/store/editOrderByDestinationPrice`
    
    selfOnboard:string = `${this.onDemand}/admin/store/selfOnboard`
    getDriverByZcode:string = `${this.onDemand}/admin/store/getDriverByZcode`

    Otpveryfication: string = `${this.onDemand}/admin/store/verifyOtp`
    // update mutiOrder fare 
    updateMultiCustomOrder = `${this.onDemand}/store/updateCustomOffer`

    // get Rider Details 
    getRiderDetails = `${this.onDemand}/wallet/rider`

   //updateMerchantPassword
     updateMerchantPassword: string = `${this.comm}/store/updateMerchantPassword`

   //verifyMerchantAccount
     verifyMerchantAccount: string = `${this.onDemand}/admin/store/verifyMerchantAccount`

   //sendMerchantUserOtp
   sendMerchantUserOtp: string = `${this.onDemand}/admin/store/sendMerchantUserOtp`

   //MerchantUserVerifyOtp
   MerchantUserVerifyOtp: string = `${this.onDemand}/admin/store/MerchantUserVerifyOtp`
}
